var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[_c('div',{staticClass:"login__container"},[_vm._m(0),_c('div',{staticClass:"login__container--right"},[_c('img',{staticClass:"aljay-logo",attrs:{"src":require("../../assets/branding/new-logo-aljay.png"),"alt":"Aljay logo "}}),_vm._m(1),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"vid":"email","name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{staticClass:"form_label",attrs:{"for":"emailInput"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.credentials.email),expression:"credentials.email"}],class:'form-control mb-0' +
                    (errors.length > 0 ? ' is-invalid' : ''),attrs:{"id":"emailInput","type":"email","aria-describedby":"emailHelp","placeholder":"Enter Email","disabled":_vm.isBusy,"autocomplete":"off"},domProps:{"value":(_vm.credentials.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.credentials, "email", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"password","name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',[_c('label',{staticClass:"form_label",attrs:{"for":"passwordInput"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.credentials.password),expression:"credentials.password"}],class:'form-control mb-0' +
                    (errors.length > 0 ? ' is-invalid' : ''),attrs:{"id":"passwordInput","type":"password","placeholder":"Enter Password","required":"","disabled":_vm.isBusy,"autocomplete":"off"},domProps:{"value":(_vm.credentials.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.credentials, "password", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('div',{staticClass:"d-inline-block w-100"},[_c('b-button',{staticStyle:{"border-radius":"50px"},attrs:{"type":"submit","variant":"success","block":"","disabled":_vm.isBusy}},[_vm._v(" Sign in ")]),_c('div',{staticClass:"login-with"},[_c('div'),_c('p',[_vm._v("or sign in with")]),_c('div')]),_c('b-button',{staticStyle:{"border-radius":"50px"},attrs:{"type":"button","variant":"secondary","block":"","disabled":_vm.isBusy},on:{"click":function($event){return _vm.useAuthProvider('oauth2', _vm.Oauth2)}}},[_vm._v(" Aljay SSO ")])],1)],1)]}}])})],1),_c('div',{staticClass:"circle circle__lg"}),_c('div',{staticClass:"circle circle__sm"})]),_c('div',{staticClass:"circle circle__one"}),_c('div',{staticClass:"circle circle__two"})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login__container--left"},[_c('img',{attrs:{"src":require("../../assets/branding/repair-img.png"),"alt":"Branding image"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('h1',{staticClass:"title"},[_vm._v(" Logistics Repair & Maintenance System ")]),_c('h2',{staticClass:"subtitle"},[_vm._v(" Sign In! ")])])}]

export { render, staticRenderFns }