<template>
  <div class="login">
    <div class="login__container">
      <div class="login__container--left">
        <img
          src="../../assets/branding/repair-img.png"
          alt="Branding image"
        >
      </div>
      <div class="login__container--right">
        <img
          class="aljay-logo"
          src="../../assets/branding/new-logo-aljay.png"
          alt="Aljay logo "
        >
        <div class="header">
          <h1 class="title">
            Logistics Repair & Maintenance System
          </h1>
          <h2 class="subtitle">
            Sign In!
          </h2>
        </div>
        <ValidationObserver
          ref="form"
          v-slot="{ handleSubmit }"
        >
          <form
            novalidate
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <ValidationProvider
              #default="{ errors }"
              vid="email"
              name="email"
              rules="required|email"
            >
              <b-form-group>
                <label
                  for="emailInput"
                  class="form_label"
                >Email</label>
                <input
                  id="emailInput"
                  v-model="credentials.email"
                  type="email"
                  :class="
                    'form-control mb-0' +
                      (errors.length > 0 ? ' is-invalid' : '')
                  "
                  aria-describedby="emailHelp"
                  placeholder="Enter Email"
                  :disabled="isBusy"
                  autocomplete="off"
                >
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider
              #default="{ errors }"
              vid="password"
              name="password"
              rules="required"
            >
              <b-form-group>
                <label
                  for="passwordInput"
                  class="form_label"
                >Password</label>
                <!-- <router-link
              to="/auth/password-reset1"
              class="float-right"
            >
              Forgot password?
            </router-link> -->
                <input
                  id="passwordInput"
                  v-model="credentials.password"
                  type="password"
                  :class="
                    'form-control mb-0' +
                      (errors.length > 0 ? ' is-invalid' : '')
                  "
                  placeholder="Enter Password"
                  required
                  :disabled="isBusy"
                  autocomplete="off"
                >
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
            <div class="d-inline-block w-100">
              <b-button
                type="submit"
                variant="success"
                block
                :disabled="isBusy"
                style="border-radius: 50px"
              >
                Sign in
              </b-button>
              <div class="login-with">
                <div />
                <p>or sign in with</p>
                <div />
              </div>

              <b-button
                type="button"
                variant="secondary"
                block
                :disabled="isBusy"
                style="border-radius: 50px"
                @click="useAuthProvider('oauth2', Oauth2)"
              >
                Aljay SSO
              </b-button>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div class="circle circle__lg" />
      <div class="circle circle__sm" />
    </div>
    <div class="circle circle__one" />
    <div class="circle circle__two" />
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, SharedAuthService, SharedSSOService } from '@/services'
import { Oauth2 } from 'universal-social-auth'

export default {
  name: 'SharedSignIn',

  middleware: ['guest'],

  metaInfo () {
    return {
      title: 'Sign In'
    }
  },

  data: () => ({
    isBusy: false,
    credentials: {
      email: '',
      password: ''
    },
    Oauth2
  }),

  mounted () {
    core.index()
  },

  methods: {
    useAuthProvider (provider, proData) {
      this.$Oauth
        .authenticate(provider, proData)
        .then(data => {
          this.useSocialLogin(data)
        })
        .catch(err => {
          console.log(err)
        })
    },

    async useSocialLogin (code) {
      await SharedSSOService.post(code)
        .then(response => {
          this.$store.dispatch('auth/saveToken', response.data.token)

          SharedAuthService.FetchUser()
            .then(({ data }) => {
              this.$store.dispatch('auth/fetchUserSuccess', data)

              let routeName = 'dashboard'

              const user = this.$store.getters['auth/user']

              if (user) {
                routeName = `${user.role}.dashboard`
              }

              this.$router.replace({ name: routeName })
            })
            .catch(() => {
              this.$store.dispatch('auth/fetchUserFailure')
            })
        })
        .catch(err => {
          console.log({ err: err })
        })
    },

    async onSubmit () {
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.isBusy = true
          SharedAuthService.SignIn(this.credentials)
            .then(response => {
              this.$store.dispatch('auth/saveToken', response.data.token)

              SharedAuthService.FetchUser()
                .then(({ data }) => {
                  this.$store.dispatch('auth/fetchUserSuccess', data)

                  let routeName = 'dashboard'

                  const user = this.$store.getters['auth/user']

                  if (user) {
                    routeName = `${user.role}.dashboard`
                  }

                  this.$router.replace({ name: routeName })
                })
                .catch(() => {
                  this.$store.dispatch('auth/fetchUserFailure')
                })

              this.isBusy = false
            })
            .catch(error => {
              if (error instanceof AxiosError) {
                if (error.code === 422) {
                  this.$refs.form.setErrors(error.message)
                }
              }
              this.isBusy = false
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  background: #f8fafc;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  &__container {
    position: relative;
    background: white;
    display: grid;
    max-width: 1024px;
    grid-template-columns: repeat(5, 1fr);
    margin: 0 auto;
    box-shadow: -1px 3px 63px -3px rgba(227, 219, 219, 0.74);
    -webkit-box-shadow: -1px 3px 63px -3px rgba(227, 219, 219, 0.74);
    -moz-box-shadow: -1px 3px 63px -3px rgba(227, 219, 219, 0.74);
    border-radius: 10px;
    &--left {
      background: #fafafa;
      grid-column: 1 / 3;
      padding: 1rem;
      display: flex;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      img {
        max-width: 100%;
        margin: auto 0;
      }
    }
    &--right {
      padding: 2rem 3rem;
      grid-column: 3 / 6;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 1rem;
      background: transparent;
      .aljay-logo {
        height: 60px;
        max-width: 100%;
        align-self: flex-start;
        margin-left: -0.6rem;
      }
      .title,
      .subtitle {
        line-height: 1.2;
      }
      .title {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0.3rem;
      }
      .subtitle {
        font-size: 14px;
        font-weight: 400;
      }
      .login-with {
        display: grid;
        grid-template-columns: 1fr max-content 1fr;
        justify-content: center;
        align-items: center;
        margin: 0.5rem 0;
        div {
          height: 1px;
          background: #e5e7eb;
        }
        p {
          margin: 0 auto;
          padding: 0 1rem;
        }
      }
    }
  }
  .circle {
    position: absolute;
    box-shadow: 1px 0px 32px -10px rgba(26, 5, 5, 0.79);
    -webkit-box-shadow: 1px 0px 32px -10px rgba(26, 5, 5, 0.79);
    -moz-box-shadow: 1px 0px 32px -10px rgba(26, 5, 5, 0.79);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    &__lg {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      top: 0;
      right: 0;
      background: #06c270;
      transform: translate(20px, -20px);
    }
    &__sm {
      top: 50%;
      right: 0;
      background: #fb923c;
      transform: translate(15px, -25px);
    }

    &__one {
      left: 50%;
      top: 2rem;
      background: #fb923c;
    }
    &__two {
      left: 15%;
      bottom: 2rem;
      background: #06c270;
    }
  }
}

@media screen and (max-width: 1200px) {
  .login {
    &__container {
      max-width: 992px;
      &--left {
        .wifi-icon {
          max-width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .login {
    &__container {
      max-width: 830px;
      &--left {
        .wifi-icon {
          max-width: 100%;
        }
      }
    }
    .circle {
      &__lg {
        width: 40px;
        height: 40px;
      }

      &__one {

        top: 1rem;

      }
      &__two {

        bottom: 1rem;

      }
    }
  }
}

@media screen and (max-width: 867px) {
  .login {
    &__container {
      max-width: 768px;
      &--left {
        .wifi-icon {
          max-width: 100%;
        }
      }
      &--right {
        .title {
          font-size: 18px;
        }
      }
    }
    .circle {
      &__lg {
        width: 40px;
        height: 40px;
      }

      &__one {

        top: 1rem;

      }
      &__two {
        left: 15%;
        bottom: 2rem;

      }
    }
  }
}

@media screen and (max-width: 768px) {
  .login {
    &__container {
      width: 100%;
      margin: 0 2rem;
      grid-template-columns: 1fr;
      &--left {
        display: none;
      }
      &--right {
        padding: 2rem 3rem;
        grid-column: 1/5;
        .aljay-logo {
        margin: 0 auto;
        }

        .title {
          text-align: center;
          font-size: 24px;
          margin-bottom: 1rem;
        }
        .subtitle {
          font-size: 20px;

        }
      }
    }
    .circle {
      &__one {
       top: 0;
      }
      &__two {
        bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 567px) {
  .login {
    &__container {
      width: 100%;
      margin: 0 2rem;
      grid-template-columns: 1fr;

      &--left {
        display: none;
      }
      &--right {
         gap: 1rem;
        padding: 1rem;

        .title {
          text-align: center;
          font-size: 1.5rem;
          margin-bottom: 1.5rem;
        }
        .subtitle {
          font-size: 1rem;

        }
      }
    }
    .circle {
      &__sm {
        display: none;
      }
      &__one {
       top: 1rem;
      }
      &__two {
        bottom: 1rem;
      }
    }
  }
}

@media screen and (max-width: 367px) {
  .login {
    &__container {

      &--right {
        .title {
          margin-bottom: 0.5rem;
        }
      }
    }
    .circle {
      &__one {
       top: 2rem;
      }
      &__two {
        bottom: 2rem;
      }
    }
  }
}
</style>
